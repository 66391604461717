import React from "react";
import Header from "../hireform/Headerform";
import "./styles.css";
import UpdatedFooter from "../footer/UpdatedFooter";
function TermsAndConditions() {
  return (
    <>
      <Header />

      <div className="terms-and-condtions-main-container">
        <p className="terms-and-condtions-heading">
          Terms & Conditions January 2025
        </p>
        <div class="terms-and-condtions-content">
          <p>This Agreement was last modified on January 2, 2025.</p>

          <p>
            Please read these Terms and Conditions (“Agreement”, “Terms and
            Conditions”) carefully before using{" "}
            <a href="https://theremotepad.com/">https://theremotepad.com/</a>{" "}
            (“the Site”) operated by The Remote Pad Ltd (“us”, “we”, or “our”).
            This Agreement sets forth the legally binding terms and conditions
            for your use of the Site at{" "}
            <a href="https://theremotepad.com/">https://theremotepad.com/</a>.
          </p>

          <p>
            The Site is a curated matchmaking platform designed for referring
            Approved Professionals who provide remote work services, consulting,
            or other project-based assistance required by the Client for various
            tasks.
          </p>

          <p>
            Our role is pre-vetting and referring Approved Professionals to
            Clients and facilitating the services provided by Approved
            Professionals to the Clients. The Remote Pad is not a provider of
            the services offered by Approved Professionals. Approved
            Professionals are independent contractors and are not employees of
            The Remote Pad.
          </p>

          <p>
            It is the Client’s responsibility to decide whether to engage the
            Approved Professional for the provision of the services. Should the
            Client decide to engage the Approved Professional, a contract (the
            “Service Agreement”) will be formed directly between such Client and
            The Remote Pad. All payments pursuant to the Service Agreement
            between the Client and the Approved Professional shall be processed
            through The Remote Pad.
          </p>

          <p>
            By accessing or using the Site in any manner, including, but not
            limited to, visiting or browsing the Site or contributing content or
            other materials to the Site, you agree to be bound by these Terms
            and Conditions. Capitalized terms are defined in this Agreement.
          </p>

          <h2>Additional Definitions:</h2>
          <p>
            <strong>Clients (“Users”):</strong> users who request the services
            of experts on the Site.
          </p>
          <p>
            <strong>Approved Professionals:</strong> experts who provide
            services to Clients.
          </p>

          <h2>Intellectual Property</h2>
          <p>
            The Site and its original content, features, and functionality are
            owned by The Remote Pad and are protected by international
            copyright, trademark, patent, trade secret, and other intellectual
            property or proprietary rights laws.
          </p>
          <p>
            You may NOT repurpose content on the Site without permission from
            the owner. You are encouraged to share links to our content instead.
          </p>

          <h2>Termination</h2>
          <p>
            We may terminate your access to the Site, without cause or notice,
            which may result in the forfeiture and destruction of all
            information associated with you. All provisions of this Agreement
            that by their nature should survive termination shall survive
            termination, including, without limitation, ownership provisions,
            warranty disclaimers, indemnity, and limitations of liability.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            By using our services, you agree that The Remote Pad will not be
            liable for any indirect, incidental, special, or consequential
            damages, loss of revenue, loss of data, or emotional distress
            resulting from your use of the Site or services. We do not guarantee
            that the content on our Site is error-free.
          </p>

          <h2>Links To Other Sites</h2>
          <p>
            Our Site may contain links to third-party sites that are not owned
            or controlled by The Remote Pad. Trademarks of third-party sites,
            such as names and logos, may be used on our Site to identify those
            sites. We do not claim any ownership of those names, slogans, or
            logos.
          </p>
          <p>
            The Remote Pad has no control over and assumes no responsibility for
            the content, privacy policies, or practices of any third-party sites
            or services. We strongly advise you to read the terms and conditions
            and privacy policy of any third-party site that you visit.
          </p>

          <h2>Governing Law</h2>
          <p>
            This Agreement (and any further rules, policies, or guidelines
            incorporated by reference) shall be governed and construed in
            accordance with the laws of [Your Governing Region], without giving
            effect to any principles of conflicts of law.
          </p>

          <h2>Changes To This Agreement</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms and Conditions by posting the updated terms on the Site.
            Your continued use of the Site after any such changes constitutes
            your acceptance of the new Terms and Conditions.
          </p>
          <p>
            Please review this Agreement periodically for changes. If you do not
            agree to any part of this Agreement or any changes to this
            Agreement, do not use, access, or continue to access the Site, or
            discontinue any use of the Site immediately.
          </p>
        </div>
      </div>
      <UpdatedFooter/>
    </>
  );
}

export default TermsAndConditions;
