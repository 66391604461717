import React, { useState, useEffect } from "react";
import "./about-hiring-candidate.css";
import emailjs from "emailjs-com";
import $ from "jquery";
import axios from "axios";
import Spinner from "../../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchCountries } from "../../../utils/AxiosServices/fetchCountries";
import { fetchCountryCities } from "../../../utils/AxiosServices/fetchCountryCities";
import { sendWelcomeEmail } from "../../../utils/AxiosServices/sendWellcomeEmail";
// import { Dropbox } from "dropbox";
// import PhoneInput from 'react-phone-number-input'

const AboutHirinCandidate = ({
  val,
  setNo,
  compAdd,
  custAdd,
  myform,
  setmyform,
  setCompAdd,
  next,
  back,
  // err,
  clearData,
  selectedQty,
  selectedSkills,
  selectedTeams,
}) => {
  const [value, setValue] = useState("1");
  const [err, setError] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [resumeUrl, setResumeUrl] = useState("");
  const [documentLoading, setDocumentLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [varified, setVarified] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [agreement, setAgreement] = useState(false);

  let filename;

  const getAllCountries = async () => {
    try {
      const response = await fetchCountries();
      console.log("response", response);
      setCountries(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryCities = async (id) => {
    try {
      const response = await fetchCountryCities(id);
      console.log("response", response);
      setCities(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  console.log(selectedFile);
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    filename = file.name;

    // Check if the file size is less than or equal to 2MB
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file && file.size > maxSizeInBytes) {
      alert("File size exceeds 2MB. Please choose a smaller file.");
      return;
    }

    // Check if the file type is either 'application/pdf' or 'application/msword'
    const allowedFileTypes = ["application/pdf"];
    if (file && !allowedFileTypes.includes(file.type)) {
      alert("Invalid file type. Please choose a PDF document.");
      return;
    }

    setSelectedFile(file);
    const form = new FormData();
    form.append("file", file);
    uploadFile(form);
    console.log("check 1 =>");
  };

  const uploadFile = async (file) => {
    try {
      setDocumentLoading(true);
      console.log(file, "current file");
      const res = await fetch(
        // "https://remotepad.bitsclansolutions.com/api/upload",
        `${process.env.REACT_APP_API_URL}/api/upload`,

        {
          method: "POST",
          body: file,
        }
      );
      const data = await res.json();
      setResumeUrl(data.url);
      setFileName(filename);
      console.log(data);
      setDocumentLoading(false);
      toast.success("Successfull");
    } catch (e) {
      console.log(e);
      setDocumentLoading(false);
      toast.error("Failed to upload your resume try again");
    }
  };

  console.log(resumeUrl);

  setNo(value);
  useEffect(() => {
    setValue(val);
    $(".PhoneInputCountryIcon").addClass("democlass");
    $(".democlass").append("<p>Select</p>");
  }, []);

  const handleOnchange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setmyform({ ...myform, [name]: value });
  };

  const handleChangeCountry = (e) => {
    const name = e.target.name;
    const value = e.target.value; // id as a string

    if (name === "myformcandidatecountry") {
      const selectedCountry = countries.find(
        (item) => item.id === parseInt(value, 10) // Ensure type match
      );

      if (!selectedCountry) {
        console.error("No country found for id:", value);
        return;
      }

      // Update the state with the selected country's id
      setmyform({
        ...myform,
        [name]: selectedCountry.id, // Save the id
        myformcandidatecity: "",
      });

      // Fetch cities or perform other actions
      console.log("Selected Country =>", selectedCountry);
      setCities([]);
      getCountryCities(selectedCountry.id);
    }
  };

  const selectedCountry = countries.find(
    (item) => item.id === myform.myformcandidatecountry // Ensure type match
  );
  const sheetName = "JoinATeamSheet";

  const state = {
    RemoteTeam: JSON.stringify(selectedTeams),
    Skills: JSON.stringify(selectedSkills),
    Experience: selectedQty,
    GraduationYear: myform.myformGraduationyear,
    EnglishComLevel: myform.myformenglishcomlevel,
    ClientCommunication: myform.myformclientcomhours,
    FirstName: myform.myformName,
    LastName: myform.myformLastName,
    Email: myform.myformEmail,
    SkypeId: myform.myformSkypeId,
    Contact: myform.myformContact,
    EmergencyContact: myform.myformAlternateContact,
    Address: myform.myformcompLoc,
    CompanyEmployees: myform.myformcompEmp,
    Country: selectedCountry?.name,
    City: myform.myformcandidatecity,
    SheetName: sheetName,
    ResumeUrl: resumeUrl,
  };

  console.log(state);

  const handleSendWellcomeEmail = async () => {
    try {
      const data = {
        name: state.FirstName + state.LastName,
        email: state.Email,
      };
      const response = await sendWelcomeEmail(data);
      if (response.status === 200) {
        next();
      }
      console.log("response after wellcome email", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendForm = () => {
    setLoading(true);
    const formData = new FormData();
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        const value = state[key];

        if (Array.isArray(value)) {
          // If the value is an array, append each element separately
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    fetch(process.env.REACT_APP_SECOND_GSHEET_URL, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        // res.json();
        console.log("Res from sheet", res);
        if (res.status === 200) {
          handleSendWellcomeEmail();
        }
      })
      .catch((error) => console.log(error));
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (myform?.myformGraduationyear !== "") {
      const selectedDate = new Date(myform?.myformGraduationyear);
      const currentDate = new Date();
      const yearsDiff = currentDate.getFullYear() - selectedDate.getFullYear();
      let yearsOfExp = parseInt(selectedQty);

      if (selectedQty === ">06") {
        yearsOfExp = 6;
      }

      console.log("yearsDiff", yearsDiff);
      console.log("selectedQty", yearsOfExp);

      if (yearsDiff < yearsOfExp) {
        toast.error(
          "You are not qualified for this position because your work experience and year of graduation doesn't match!"
        );
        setmyform({ ...myform, myformGraduationyear: "" });
        return;
      }
    }
    if (!verified) {
      setCaptchaError(true);
      toast.error("Confirm the human verification");
      return;
    }
    if (resumeUrl) {
      sendForm();
    } else {
      toast.error("Resume is required");
      // return;
    }
  };

  const nextStep = () => {
    next();
    clearData();
  };
  const backStep = () => {
    back();
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
    if (verified) {
      setCaptchaError(false);
    }
  }

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <div className="mt-4 inp-wrapper wrapper">
          <form onSubmit={submitForm}>
            <div className=" ">
              <h1 className="set-remote-font text-center px-3 mt-2 mb-5 fw-bold w-md-50 mx-auto">
                We would like to learn more about <br />
                <span className="hilight ">you !</span>
              </h1>

              <div className="row py-3 px-wrap gx-5 mx-xl-5">
                <div
                  className=" col-md-6 mb-3 info px-3 set-my-input-relative"
                  key="1"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    maxLength="20"
                    type="text"
                    className="form-control form-control-lg"
                    name={"myformName"}
                    value={myform.myformName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 1 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 info px-3 set-my-input-relative"
                  key="2"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    maxLength="55"
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformLastName"
                    value={myform.myformLastName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 2 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 px-3 set-my-input-relative"
                  key="3"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    maxLength="32"
                    type="email"
                    name="myformEmail"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformEmail}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 3 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3  px-3 set-my-input-relative"
                  key="4"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Skype ID
                  </label>
                  <input
                    maxLength="35"
                    type="text"
                    name="myformSkypeId"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformSkypeId}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 4 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 px-3 info set-my-input-relative"
                  key="5"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Contact No <span className="text-danger">*</span>
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="tel"
                    className="form-control form-control-lg"
                    id="comp_name"
                    name="myformContact"
                    value={myform.myformContact}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 5 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 px-3 info set-my-input-relative"
                  key="6"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Emergency Contact
                  </label>
                  <input
                    maxLength="25"
                    type="tel"
                    className="form-control form-control-lg"
                    id="comp_name"
                    name="myformAlternateContact"
                    value={myform.myformAlternateContact}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 5 && errMsg}</small>
                </div>
                {/* <div
                  className=" col-md-6 mb-3 px-3 set-my-input-relative"
                  key="7"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Industry
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompIndustry"
                    value={myform.myformcompIndustry}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div> */}
                <div
                  className="col-md-6 mb-3 px-3 set-my-input-relative"
                  key="8"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    English Communication Level{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    maxLength="25"
                    required
                    type="text"
                    className="form-select form-select-lg px-3"
                    id="exampleFormControlInput1"
                    name="myformenglishcomlevel"
                    value={myform.myformenglishcomlevel}
                    onChange={handleOnchange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value={"Fluent"}>Fluent</option>
                    <option value={"Native"}>Native</option>
                  </select>
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>
                <div
                  className="col-md-6 mb-3 px-3 set-my-input-relative"
                  key="9"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Do you communicate with the clients{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    maxLength="25"
                    required
                    type="text"
                    className="form-select form-select-lg px-3"
                    id="exampleFormControlInput1"
                    name="myformclientcomhours"
                    value={myform.myformclientcomhours}
                    onChange={handleOnchange}
                  >
                    <option value={""} disabled>
                      Select an option
                    </option>

                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 px-3 set-my-input-relative"
                  key="10"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompLoc"
                    value={myform.myformcompLoc}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 7 && errMsg}</small>
                </div>

                <div
                  className="col-md-6 mb-3 px-3 set-my-input-relative"
                  key="11"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Select Country <span className="text-danger">*</span>
                  </label>
                  <select
                    maxLength="25"
                    required
                    type="text"
                    className="form-select form-select-lg px-3"
                    id="exampleFormControlInput1"
                    name="myformcandidatecountry"
                    value={myform.myformcandidatecountry}
                    onChange={handleChangeCountry}
                  >
                    <option value="" disabled>
                      Select an option{" "}
                    </option>
                    {countries &&
                      countries?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>

                <div
                  className="col-md-6 mb-3 px-3 set-my-input-relative"
                  key="12"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Select City <span className="text-danger">*</span>
                  </label>
                  <select
                    maxLength="25"
                    required
                    type="text"
                    className="form-select form-select-lg px-3"
                    id="exampleFormControlInput1"
                    name="myformcandidatecity"
                    value={myform.myformcandidatecity}
                    onChange={handleOnchange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {cities &&
                      cities?.map((item) => (
                        <option key={item?.id} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>

                <div
                  className=" col-md-6 mb-3 px-3 set-my-input-relative"
                  key="13"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Select Year of graduation{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="date"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformGraduationyear"
                    value={myform.myformGraduationyear}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 7 && errMsg}</small>
                </div>

                <div className="d-flex flex-column pt-2 px-3 gap-1">
                  <label className="custom-upload-btn">
                    <span>
                      <i className="fas fa-cloud-upload-alt"></i> Upload Resume
                    </span>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      onClick={onInputClick}
                      accept=".pdf"
                      style={{ display: "none" }}
                    />
                  </label>
                  {documentLoading && (
                    <p className="resume-upload-text">
                      Your resume is uploading... Please wait.
                    </p>
                  )}
                  <p className="resume-upload-text">{fileName}</p>
                </div>

                <div
                  className="col-md-12 mb-3 px-3 d-flex gap-2 align-items-center"
                  key={"14"}
                >
                  <input
                    className="form-check-input rounded mt-0"
                    type="checkbox"
                    checked={agreement}
                    onChange={() => setAgreement(!agreement)}
                    name="aggreementCheckbox"
                    required
                    style={{ width: "1.5em" }}
                  />
                  <label htmlFor="basic-url" className="checkbox-label">
                    By checking this you agree to our{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Privacy Policy
                    </a>&ensp;{" "}
                    ,&ensp;
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Terms & Conditions
                    </a>
                    &ensp; and&ensp;
                    <a
                      href="/data-privacy-policy"
                      target="_blank"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Data Privacy Policy
                    </a>
                  </label>
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>

                <div className="px-3">
                  <ReCAPTCHA
                    sitekey="6LcsgmopAAAAAMTyP7jnxKeb-fr02SURarYrCVMS"
                    onChange={onChange}
                  />
                </div>
                {/* {captchaError && <h6 className="text-danger">Please verify</h6>} */}
              </div>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
              <button className="step-back_btn mx-1 mb-5 " onClick={backStep}>
                Back
              </button>
              <button
                type="submit"
                disabled={documentLoading}
                className="step-btn mx-1 mb-5 candidate-submit"
              >
                Next
              </button>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none">
              <button type="submit" className="step-btn mb-3 ">
                Next
              </button>
              <button className="step-back_btn mb-5 " onClick={backStep}>
                Back
              </button>
            </div>
          </form>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AboutHirinCandidate;
