import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { useEffect } from "react";

const ThankYou = ({ next, data }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 7000);
  }, []);
  const nextStep = () => {
    next();
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <div className="my-5 set-align-center-thankyou">
          <div>
            <div style={{ width: "80px", margin: "0 auto" }}>
              <video style={{ height: "100%", width: "100%" }} autoPlay muted>
                <source
                  src={
                    "https://res.cloudinary.com/dk04rvw9e/video/upload/v1736507687/remote%20email%20template%20assets/kb0qdymkz3zrj8oor2kg.mp4"
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <h1 className=" display-2  text-center fw-bold">Thank You Note:</h1>
            <p className="text-center px-3  mx-cust">
              Thank you for taking the first step toward unlocking your
              project's potential. Our team is thrilled to connect with you and
              explore how we can help bring your vision to life. Our experts
              will be in touch soon to embark on this exciting adventure
              together. Let the innovation begin!{" "}
            </p>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5">
              <Link to="/">
                <button className="step-btn mx-1 mt-4" onClick={nextStep}>
                  Back To HomePage
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYou;
