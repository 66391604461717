import React, { useEffect, useState } from "react";
import "./App.css";
import MainPage from "./components/MainPage";
import HireForm from "./components/hireform/Hireform";
import Developerpage from "./components/developerpage/Developerpage";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./components/Aboutus/Aboutus";
import JoinForm from "./components/joinform/JoinForm";
import BlogPage from "./components/blog/BlogPage";
import HireMe from "./components/HireMeForm/HireMe";
import ContactUs from "./components/ContactUs/ContactUs";
import SingleBlog from "./components/blog/SingleBlog";
import EngrSection from "./components/engrTeamSection/EngrSection";
import { useLocation } from "react-router-dom";
import Chatbot from "./ChatBot";
import LeadForm from "./components/LeadForm/LeadForm";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/Terms&Conditions/Terms&Conditions";
import DataPrivacy from "./components/DataPrivacy/DataPrivacy";
import { ToastContainer } from "react-toastify";

// const HireForm = React.lazy(() => import("./components/MainPage"));

function App() {
  const [isCometChatInitialized, setIsCometChatInitialized] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location]);
  return (
    <div className="App">
      <div>
        {/* {isCometChatInitialized && <ChatComponent />} */}
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/hire-a-team" element={<HireForm />} />
          <Route path="/join-a-team" element={<JoinForm />} />
          <Route
            path="/hire-a-developer/:techTitle"
            element={<Developerpage />}
          />
          <Route path="/:techTitle" element={<Developerpage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/hire-me-form/:techTitle" element={<HireMe />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog/:slug" element={<SingleBlog />} />
          <Route path="/engineer" element={<EngrSection />} />
          <Route path="/schedule-a-call" element={<LeadForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/data-privacy-policy" element={<DataPrivacy />} />
        </Routes>
      </div>
      {/* <Chatbot /> */}
      <ToastContainer/>
    </div>
  );
}

export default App;
