import axios from "axios";
 export const fetchCountries = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/countries`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw new Error("Failed to fetch countries");
  }
};
