import React from "react";
import Header from "../hireform/Headerform";
import "./styles.css";
import UpdatedFooter from "../footer/UpdatedFooter";
function DataPrivacy() {
  return (
    <>
      <Header />

      <div className="data-privacy-main-container">
        <p className="data-privacy-heading">Data Privacy Policy January 2025</p>
        <div class="data-privacy-content">
          <p>Effective Date: January 2, 2025</p>
          <p>
            The Remote Pad (“we,” “us,” or “our”) values your privacy and is
            committed to protecting your personal information. This Data Privacy
            Policy outlines how we collect, use, and safeguard your data when
            you use our website (
            <a href="https://theremotepad.com/">https://theremotepad.com/</a>),
            and it explains your rights regarding your information.
          </p>

          <h2>Data Collection</h2>
          <p>
            We collect personal information that you provide to us when using
            our Site. This includes but is not limited to:
          </p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Payment information (if applicable)</li>
            <li>Communication details</li>
          </ul>
          <p>
            We may also collect non-personally identifiable information such as:
          </p>
          <ul>
            <li>Browser type</li>
            <li>IP address</li>
            <li>Usage data (e.g., pages viewed, time spent on the site)</li>
          </ul>

          <h2>Use of Data</h2>
          <p>We use your data to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>
              Communicate with you about updates, promotions, or customer
              support inquiries
            </li>
            <li>Improve the functionality and user experience of our Site</li>
            <li>Process payments and manage transactions</li>
          </ul>

          <h2>Data Sharing</h2>
          <p>
            We do <strong>NOT</strong> share your personal data with third
            parties. Your information is used solely to facilitate the services
            provided by The Remote Pad.
          </p>

          <h2>Data Protection</h2>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal data against unauthorized access, alteration,
            disclosure, or destruction. These include:
          </p>
          <ul>
            <li>Secure servers</li>
            <li>Encrypted payment gateways</li>
            <li>Regular security audits</li>
          </ul>

          <h2>User Rights</h2>
          <p>
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>
              <strong>Access:</strong> Request a copy of the data we have about
              you.
            </li>
            <li>
              <strong>Correction:</strong> Update any inaccurate or incomplete
              information.
            </li>
            <li>
              <strong>Deletion:</strong> Request the removal of your personal
              data, subject to legal obligations.
            </li>
            <li>
              <strong>Restriction:</strong> Limit how we use your data in
              certain circumstances.
            </li>
            <li>
              <strong>Portability:</strong> Request a copy of your data in a
              machine-readable format.
            </li>
          </ul>
          <p>
            To exercise these rights, contact us at{" "}
            <a href="mailto:support@theremotepad.com">
              support@theremotepad.com
            </a>
            .
          </p>

          <h2>Data Retention</h2>
          <p>
            We retain your personal data for as long as necessary to provide our
            services or comply with legal requirements. Once the retention
            period expires, we securely delete or anonymize your data.
          </p>

          <h2>Changes to this Policy</h2>
          <p>
            We reserve the right to update this Data Privacy Policy at any time.
            Any changes will be posted on this page with an updated effective
            date. We encourage you to review this policy periodically.
          </p>
        </div>
      </div>
      <UpdatedFooter/>
    </>
  );
}

export default DataPrivacy;
