import React from "react";
import Header from "../hireform/Headerform";
import "./styles.css";
import UpdatedFooter from "../footer/UpdatedFooter";
function PrivacyPolicy() {
  return (
    <>
      <Header />

      <div className="privacy-policy-main-container">
        <p className="privacyPolicyHeading">PRIVACY POLICY January 2025</p>

        <div className="privacyPolicyContent">
          <p>This Privacy Policy was last modified on January 2, 2025.</p>
          <p>
            theremotepad, Inc ("us", "we", or "our") operates theremotepad.com
            ("The Remote Pad"), collectively referred to as the "Site" or the
            "Platform." This page outlines our policies regarding the
            collection, use, and disclosure of personal information we receive
            from users of the Site.
          </p>
          <p>
            <strong>Additional definitions:</strong>
            <br />
            <strong>Clients ("Users"):</strong> Users who request the assistance
            of experts on the Site.
            <br />
            <strong>Mentors:</strong> Experts who provide services to Clients or
            contribute content on the Site.
          </p>
          <p>
            We use your personal information to provide services and improve the
            Site. By using the Site, you consent to the collection and use of
            information in accordance with this policy. Unless otherwise defined
            in this Privacy Policy, terms used here have the same meanings as in
            our Terms and Conditions, accessible at theremotepad.com/terms.
          </p>

          <h4>1. Information We Collect and Receive</h4>
          <h5>1.1 Information You Provide</h5>
          <p>
            This includes information you actively share when taking specific
            actions on the Site, such as creating an account, updating your
            profile, or completing payment activities.
          </p>
          <p>
            While using our Site, we may request certain personally identifiable
            information ("personal information" or “personal data”) that can be
            used to contact or identify you. This information may include but is
            not limited to your name, email address, profile photo, and payment
            details.
          </p>
          <p>
            You may also provide non-identifying data, such as additional
            profile details like your expertise, timezone, and language
            preferences.
          </p>

          <h5>
            1.2 Information Automatically Collected from Your Use of the
            Platform and Payment Services
          </h5>
          <p>
            In addition to the information you provide, we automatically collect
            data when you interact with the Platform.
          </p>
          <ul>
            <li>
              <strong>Usage Information:</strong> We gather data on your
              interactions with the Site, such as requests posted, session
              history, freelance jobs, pages viewed, posts liked or followed,
              and payment history.
            </li>
            <li>
              <strong>Log Data and Device Information:</strong> We collect
              details sent by your browser when visiting the Site ("Log Data"),
              which may include your computer’s IP address, browser type,
              browser version, pages visited, time and date of visits, time
              spent on pages, and other statistics.
            </li>
            <li>
              <strong>Cookies:</strong> Cookies are small files containing data,
              including anonymous unique identifiers, sent to your browser and
              stored on your device. We use cookies to collect information. You
              can configure your browser to refuse cookies or notify you when
              cookies are being sent. However, some Site features may not
              function properly without cookies. See our full cookie policy for
              more details.
            </li>
          </ul>

          <h5>1.3 Information from Third Parties</h5>
          <p>
            If you connect or log in to your account on The Remote Pad via a
            third-party service (e.g., Google, Twitter, GitHub), that service
            may provide us with information such as your email and profile data.
            The specifics depend on your privacy settings with the third-party
            service.
          </p>
        </div>
      </div>
      <UpdatedFooter/>
    </>
  );
}

export default PrivacyPolicy;
