const Scope = [
  {
    id: 1,
    stack: "Senior Fullstack Engineer",
    skills: [
      "Swift",
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "Java",
      "wordpress",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Bootstrap5",
      "React Native",
      "Rails",
      "Python",
      "Laravel",
    ],
  },
  {
    id: 2,
    stack: "Mid-level Fullstack Engineer",
    skills: [
      "Swift",
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "Java",
      "wordpress",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Bootstrap5",
      "React Native",
      "Rails",
      "Python",
      "Laravel",
    ],
  },
  {
    id: 3,
    stack: "Junior Fullstack Engineer",
    skills: [
      "Swift",
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "Java",
      "wordpress",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Bootstrap5",
      "React Native",
      "Rails",
      "Python",
      "Laravel",
    ],
  },
  {
    id: 4,
    stack: "Senior Backend Engineer",
    skills: [
      "C#",
      "Aws",
      "Swift",
      "Python",
      "Java",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Rails",
      "Laravel",
      "MySQL",
      "MongoDB",
    ],
  },
  {
    id: 5,
    stack: "Mid-level Backend Engineer",
    skills: [
      "C#",
      "Aws",
      "Swift",
      "Python",
      "Java",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Rails",
      "Laravel",
      "MySQL",
      "MongoDB",
    ],
  },
  {
    id: 6,
    stack: "Junior Backend Engineer",
    skills: [
      "C#",
      "Aws",
      "Swift",
      "Python",
      "Java",
      "Flutter",
      "Android",
      "Node Js",
      "PHP",
      "Rails",
      "Laravel",
      "MySQL",
      "MongoDB",
    ],
  },
  {
    id: 7,
    stack: "Senior Frontend Developer",
    skills: [
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "wordpress",
      "Bootstrap5",
      "React Native",
    ],
  },
  {
    id: 8,
    stack: "Mid-level Frontend Developer",
    skills: [
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "wordpress",
      "Bootstrap5",
      "React Native",
    ],
  },
  {
    id: 9,
    stack: "Junior Frontend Developer",
    skills: [
      "Html/Css",
      "Vue Js",
      "React Js",
      "JavaScript",
      "wordpress",
      "Bootstrap5",
      "React Native",
    ],
  },
  {
    id: 10,
    stack: "Senior WordPress Developer",
    skills: [
      "Html/Css",
      "PHP",
      "JavaScript",
      "Elementor",
      "Divi",
      "Beaver",
      "SQL",
      "MySQL",
      "SEO",
    ],
  },
  {
    id: 11,
    stack: "Mid-level WordPress Developer",
    skills: [
      "Html/Css",
      "PHP",
      "JavaScript",
      "Elementor",
      "Divi",
      "Beaver",
      "SQL",
      "MySQL",
      "SEO",
    ],
  },
  {
    id: 12,
    stack: "Junior WordPress Developer",
    skills: [
      "Html/Css",
      "PHP",
      "JavaScript",
      "Elementor",
      "Divi",
      "Beaver",
      "SQL",
      "MySQL",
      "SEO",
    ],
  },
  {
    id: 13,
    stack: "Senior Graphic Designer",
    skills: [
      "Photoshop",
      "InDesign",
      "Illustrator ",
      "UI,UX",
      "Figma",
      "XD",
      "Typography",
      "Branding",
    ],
  },
  {
    id: 14,
    stack: "Mid-level Graphic Designer",
    skills: [
      "Photoshop",
      "InDesign",
      "Illustrator ",
      "UI,UX",
      "Figma",
      "XD",
      "Typography",
      "Branding",
    ],
  },
  {
    id: 15,
    stack: "Junior Graphic Designer",
    skills: [
      "Photoshop",
      "InDesign",
      "Illustrator ",
      "UI,UX",
      "Figma",
      "XD",
      "Typography",
      "Branding",
    ],
  },
  {
    id: 16,
    stack: "Senior Quality Assurance",
    skills: [
      "Functional testing",
      "Quality control",
      "QA testing",
      "Quality Control",
      "Functionality Testing",
      "Automation Testing",
      "Selinium",
      "API Testing",
      "Postman",
    ],
  },
  {
    id: 17,
    stack: "Client success manager",
    skills: [
      "Project Managment",
      "SaaS Client Management",
      "Sales Experience",
      "CRM Client Management",
      "Quotes Creation",
      "Orders Creation",
      "Product Knowledge",
      "Development Experience",
    ],
  },
  {
    id: 18,
    stack: "Senior DevOps Engineer",
    skills: [
      "AWS",
      "Azure",
      "Docker",
      "GCP",
      "CD/CI Pipelines",
      "Jenkins",
      "Git",
      "GitLab CI",
      "Embedded Sysytems",
      "Raspberry Pi",
    ],
  },
  {
    id: 19,
    stack: "Senior Flutter developer",
    skills: [
      "IOS",
      "Andriod",
      "API Integration",
      "Swift",
      "Cocoa Touch",
      "Firebase",
      "Mysql",
      "JSON",
      "XML",
    ],
  },
  {
    id: 20,
    stack: "Senior SMM",
    skills: [
      "Content Creation",
      "Analytics Manager",
      "Copywriting",
      "Video Editing",
      "Social Media Strategy",
      "SEO Knowledge",
      "Branding",
      "Facebook Ads",
      "Instagram Ads",
      "Paid Campaign Management",
      "Audience Research",
      "Sprout Social",
      "Hootsuite",
    ],
  },
  {
    id: 21,
    stack: "Email marketing specialist",
    skills: [
      "DotDigital",
      "MailChimp",
      "SendGrid",
      "Klaviyo",
      "HTML-based Templates ",
      "Adobe Creative Suite",
      "A/B testing",
      "CAN-SPAM",
      "GDPR",
      "SEO Knowledge",
    ],
  },
  {
    id: 22,
    stack: "Business Development Executive",
    skills: [
      "Negotiation",
      "Interpersonal Skills",
      "Sales Strategy",
      "Lead Generation",
      "Market Research",
      "Networking",
      "Cold Calling",
      "CRM Proficiency",
      "Contract Management",
    ],
  },
  {
    id: 23,
    stack: "Senior Content Writer",
    skills: [
      "CMS",
      "Basic HTML",
      "SEO ",
      "Copywriting",
      "Storytelling",
      "Keyword Research",
      "Headline Writing",
      "Marketing Knowledge",
      "Blogs Writing",
    ],
  },
  {
    id: 24,
    stack: "Content Strategist",
    skills: [
      "Content Planning",
      "SEMrush",
      "Ahrefs",
      "Google Analytics",
      "SEO metrics",
      "Competitive Analysis",
      "CMS",
      "UX/UI Understanding",
      "Content Optimization",
      "Keyword Research",
    ],
  },
  {
    id: 25,
    stack: "Senior Android Developer",
    skills: [
      "Andriod SDK",
      "Kotlin",
      "MVVM",
      "Dagger2",
      "JUNIT",
      "Espresso",
      "Mockito",
      "Git",
      "RxJava",
    ],
  },
  {
    id: 26,
    stack: "Senior IOS Developer",
    skills: [
      "Objective-C",
      "Swift",
      "Xcode",
      "Dagger2",
      "JUNIT",
      "Espresso",
      "Mockito",
      "MVM",
      "MVVM",
    ],
  },
  {
    id: 27,
    stack: "Senior ML Engineer",
    skills: [
      "Python",
      "C++",
      "R",
      "Julia",
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "LightGBM",
      "OpenCV",
    ],
  },
  {
    id: 28,
    stack: "Senior Data Sciencetist",
    skills: [
      "ML",
      "DL",
      "NLP",
      "Data Mining",
      "BI",
      "Python",
      "NumPy",
      "Keras",
      "Docker",
      "GCP",
      "Kubernetes",
      "AWS",
      "Azure",
    ],
  },
  ,
  {
    id: 28,
    stack: "Senior Blockchain Developer",
    skills: [
      "dApps",
      "Smart Contracts",
      "NLP",
      "DLT",
      "Consensus Algorithms",
      "NFT Development",
      "Solidity",
      "Python",
      "C++",
      "DeFi",
      "Kubernetes",
      "Ethers.js",
      "Truffle",
      "Ethereum",
      "Solana",
      "Avalanche",
    ],
  },
];

export default Scope;
