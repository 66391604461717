export const SeniorFullstackJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Establishing strong professional relationships with clients and other professionals.",
  "Supervising junior engineers, analyzing areas of weakness, and scheduling training sessions accordingly.",
  "Develop software solutions by studying information needs; conferring with users; studying systems flow, data usage, and work processes; investigating problem areas; following the software development lifecycle.",
  "Evaluate the performance of the team and suggest improvements for their growth.",
  "Determine operational feasibility by evaluating analysis, problem definition, requirements, solution development, and proposed solutions.",
  "Document and demonstrate solutions by developing documentation, flowcharts, layouts, diagrams, charts, code comments, and clear code.",
  "Prepare and install solutions by determining and designing system specifications, standards, and programming.",
  "Improve operations by conducting systems analysis; recommending changes in policies and procedures.",
  "Update job knowledge by studying state-of-the-art development tools, programming techniques, and computing equipment; participating in educational opportunities; reading professional publications; maintaining personal networks; participating in professional organizations.",
  "Provide information by collecting, analyzing, and summarizing development and service issues.",
  "Protect operations by keeping information confidential.",
  "Accomplish engineering and organization mission by completing related results as needed.",
  "Support and develop software engineers by providing advice, coaching, and educational opportunities.",
  "Mentor junior and mid-level engineers, and manage their KPIs.",
  "Collaborate with the team to brainstorm and create new products.",
  "Grow engineering teams by interviewing, recruiting, and hiring.",
  "Make informed decisions quickly and take ownership of services and applications at scale.",
  "Work collaboratively with others to achieve goals.",
  "Be a persistent, creative problem-solver.",
  "Stay on the leading edge of development practices.",
  "Be passionate about great technologies, especially open source.",
  "Understand business needs and know how to create the tools to manage them.",
];

export const SeniorFlutterJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones and deliverables.",
  "Communicating effectively with clients and team.",
  "Develop and maintain high-quality, production-ready mobile applications using the Flutter framework.",
  "Collaborate with cross-functional teams to define, design, and ship new features.",
  "Ensure the performance, quality, and responsiveness of applications.",
  "Identify and troubleshoot issues, bottlenecks, and bugs, and provide solutions.",
  "Possess a strong understanding of Dart and proficiency in at least one object-oriented language such as Swift or Kotlin.",
  "Stay up-to-date with the latest industry trends and technologies.",
  "Participate in code and design reviews to maintain code quality standards.",
  "Continuously research and evaluate new Flutter packages and plugins for integration.",
  "Provide technical guidance and support to junior developers, if applicable.",
  "Apply coding best practices and adhere to SOLID principles and CLEAN architecture concepts.",
  "Create complex, scalable, and maintainable mobile apps with a deep understanding of various architectural patterns (MVC, MVP) and their advantages and limitations.",
  "Able to write clean and effective unit tests, widget tests, and end-to-end (e2e) tests for robust application testing.",
  "Demonstrate a clear understanding of common data structures, their space/time complexity, and the ability to select the appropriate data structure for a given scenario.",
  "Collaborate effectively within an agile development environment, participating in sprints, code reviews, and pair programming sessions.",
  "Showcase strong communication skills, take a proactive role within the team, and be open to mentoring junior developers.",
  "Exhibit an interest in giving tech talks and sharing insights gained from exploring the ever-evolving tech landscape.",
];

export const SeniorContentStrategiesJd = [
  "Develop and execute content strategies for websites, social media, emails, and branding materials.",
  "Align content strategies with the company's objectives and analyze trends to enhance visibility and engagement.",
  "Utilize various platforms to generate leads and drive business growth.",
  "Continuously evaluate the effectiveness of existing content across all platforms.",
  "Identify areas for improvement and implement innovative content solutions to enhance engagement and brand loyalty.",
  "Collaborate with marketing and design teams to ensure all content is on-brand and aligns with the company’s identity and goals.",
  "Strengthen brand presence through consistent and compelling content narratives across all channels.",
  "Write and produce engaging blogs, website content, social media posts, emails, and other digital content.",
  "Utilize SEO best practices to increase content reach and visibility.",
  "Monitor, track, and report on feedback and online reviews from audiences.",
  "Use analytics to gauge the success of content strategies and make data-driven decisions for future content.",
  "Stay up-to-date with industry trends and content best practices to keep the brand at the forefront of digital marketing innovations.",
  "Integrate new content formats and distribution methods to expand reach.",
  "Work closely with the digital marketing team and other departments to brainstorm new ideas.",
  "Optimize content delivery and maintain a cohesive brand voice across all channels.",
];

export const SeniorDevOpsJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones and deliverables.",
  "Communicating effectively with clients and team.",
  "Establishing strong professional relationships with clients and other professionals.",
  "Supervising junior engineers, analyzing areas of weakness, and scheduling training sessions accordingly.",
  "Deep knowledge of Git.",
  "Deploy updates and fixes in different languages.",
  "Experience leading an organization in the design and implementation of a new or modified DevSecOps pipeline.",
  "Experience implementing DevSecOps in a container ecosystem.",
  "Must have experience enabling CI/CD.",
  "Detailed knowledge of DoD Platform 1.",
  "Design and implement appropriate environments for DevOps tools set and provide production support.",
  "Experience in Test Driven Development and Continuous Integration Environments.",
  "Experience implementing DevSecOps pipelines in a VMware Tanzu environment.",
  "Experience working with Kubernetes, EC2, S3, and other Cloud hosting platforms.",
  "Experience implementing and maintaining DAST (Dynamic Application Security Testing) tools.",
  "Experience working within an Agile development team.",
  "Must have experience developing and documenting DevSecOps processes and software flows.",
  "Automates the provisioning of environments for DevOps Tool chain.",
  "Designs and writes complex code in several languages relevant to our existing product stack, with a focus on automation.",
  "Configures, tunes, maintains, and installs applications systems and validates system functionality.",
];

export const ColdCallingSpecialistJd = [
  "We are seeking a dynamic and results-driven Sales Executive with expertise in cold calling to join our team.",
  "The ideal candidate will have a proven track record in generating leads, building strong client relationships, and closing sales in a fast-paced environment.",
  "Conduct outbound cold calls to prospective clients to introduce products/services.",
  "Identify and qualify sales opportunities through effective communication and follow-up.",
  "Maintain and update the CRM system with accurate and timely information.",
  "Build rapport and establish relationships with potential customers.",
  "Present and explain product features and benefits tailored to client needs.",
  "Meet and exceed monthly sales targets and key performance indicators (KPIs).",
  "Collaborate with the sales and marketing teams to develop strategies for lead generation.",
  "Provide feedback on customer needs and market trends to improve offerings.",
  "Proven experience in cold calling and outbound sales (minimum 2-4 years).",
  "Excellent verbal and written communication skills.",
  "Strong persuasive and negotiation abilities.",
  "Ability to handle objections and turn prospects into customers.",
  "Proficient in CRM software and Microsoft Office Suite.",
  "Goal-oriented with a positive attitude and strong work ethic.",
  "Bachelor’s degree in Business, Marketing, or related field (preferred).",
];

export const DataScientistJd = [
  "We are seeking an experienced and motivated Data Scientist to join our dynamic team.",
  "The ideal candidate will have a strong background in data analysis, machine learning, and statistical modeling, with the ability to translate business challenges into data-driven solutions.",
  "Design, develop, and implement advanced analytics and machine learning models to solve complex business problems.",
  "Analyze large datasets to extract actionable insights and identify patterns and trends.",
  "Collaborate with cross-functional teams, including business, engineering, and product, to understand requirements and deliver data solutions.",
  "Build and maintain scalable data pipelines to automate data collection, cleaning, and preparation processes.",
  "Present findings and recommendations to stakeholders using visualizations and clear communication.",
  "Stay up-to-date with the latest industry trends and advancements in data science and machine learning.",
  "Bachelor’s or Master’s degree in Computer Science, Data Science, Statistics, Mathematics, or a related field.",
  "4+ years of professional experience in data science or a related role.",
  "Proficiency in programming languages such as Python, R, or Scala.",
  "Strong experience with machine learning frameworks like TensorFlow, PyTorch, or scikit-learn.",
  "Hands-on experience with SQL and NoSQL databases.",
  "Proficiency in data visualization tools like Tableau, Power BI, or matplotlib.",
  "Experience with cloud platforms such as AWS, GCP, or Azure is a plus.",
  "Excellent problem-solving and analytical skills.",
  "Strong communication and presentation skills.",
];

export const MachineLearningJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and deploy machine learning models to solve business challenges and enhance products and services.",
  "Optimize and fine-tune algorithms for scalability, performance, and accuracy.",
  "Build and maintain robust data pipelines to support machine learning workflows.",
  "Work closely with data scientists, data engineers, and product teams to implement and monitor ML solutions in production environments.",
  "Evaluate and select appropriate machine learning frameworks, tools, and technologies.",
  "Conduct experiments and evaluate the performance of models using metrics and benchmarks.",
  "Stay updated with the latest advancements in machine learning and AI technologies to recommend and implement innovative solutions.",
  "Document and present technical solutions and findings to stakeholders in a clear and concise manner.",
  "Proficiency in programming languages like Python, Java, or C++.",
  "Strong experience with ML libraries and frameworks such as TensorFlow, PyTorch, scikit-learn, or Keras.",
  "Proficient in using SQL and NoSQL databases for data handling.",
  "Familiarity with cloud platforms like AWS, GCP, or Azure for deploying ML models.",
  "Experience with version control systems (e.g., Git) and ML workflow tools (e.g., MLflow, Kubeflow).",
  "Knowledge of MLOps principles, including model monitoring and CI/CD for ML pipelines.",
  "Strong problem-solving skills and a solid understanding of statistical methods and data preprocessing techniques.",
];

export const AiEngineerJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and deploy AI models and algorithms to solve business challenges.",
  "Work with cross-functional teams to identify use cases and translate business requirements into AI solutions.",
  "Optimize and fine-tune AI models for performance, scalability, and accuracy.",
  "Build and maintain data pipelines for training, validation, and deployment of AI models.",
  "Conduct research to stay abreast of the latest advancements in AI and apply them to practical use cases.",
  "Collaborate with software engineers to integrate AI solutions into production systems.",
  "Implement monitoring and evaluation processes to ensure AI models perform effectively in real-world conditions.",
  "Document AI workflows, processes, and best practices.",
  "Proficiency in programming languages such as Python, R, or Java.",
  "Strong expertise in machine learning frameworks like TensorFlow, PyTorch, or scikit-learn.",
  "Hands-on experience with deep learning architectures such as CNNs, RNNs, GANs, or transformers.",
  "Familiarity with natural language processing (NLP) and computer vision techniques.",
  "Experience with cloud-based AI services (e.g., AWS SageMaker, Google AI Platform, Azure ML).",
  "Knowledge of big data frameworks like Spark, Hadoop, or Kafka is a plus.",
  "Strong understanding of statistical and mathematical foundations for AI.",
  "Excellent problem-solving and analytical skills.",
];

export const FrontendInternJd = [
  "We are seeking a motivated Front-End Development Intern to join our team and support the development of engaging and user-friendly web interfaces.",
  "The ideal candidate should have a basic understanding or prior exposure to WordPress, Shopify, and similar front-end stacks or technologies.",
  "This internship provides an excellent opportunity to contribute to real-world projects while gaining hands-on experience in front-end development.",
  "Assist in the design and development of responsive and interactive user interfaces for websites and web applications.",
  "Collaborate with the design and development teams to implement features using HTML, CSS, and JavaScript.",
  "Work with platforms like WordPress and Shopify to customize themes, plugins, and layouts.",
  "Conduct testing and debugging to ensure cross-browser compatibility and responsiveness.",
  "Learn and apply best practices for front-end coding, optimization, and performance.",
  "Support the maintenance and updates of existing websites and applications.",
  "Basic knowledge of front-end technologies, including HTML, CSS, and JavaScript.",
  "Prior exposure to WordPress, Shopify, or similar CMS platforms is a plus.",
  "Familiarity with responsive design principles and tools.",
  "Enthusiasm for learning new technologies and improving development skills.",
  "Strong attention to detail and problem-solving abilities.",
  "Ability to work collaboratively in a team environment.",
];

export const FullstackWebJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and maintain fully functional web applications, ensuring seamless integration between front-end and back-end systems.",
  "Collaborate with UI/UX designers to translate wireframes and mockups into responsive and user-friendly interfaces.",
  "Build and maintain RESTful APIs and microservices to support application functionality.",
  "Write clean, maintainable, and well-documented code following best practices.",
  "Optimize application performance, troubleshoot bugs, and resolve issues promptly.",
  "Ensure security and data protection measures are in place for web applications.",
  "Work with DevOps to deploy, monitor, and maintain applications in production environments.",
  "Stay up-to-date with the latest trends and technologies in web development.",
  "Proficiency in front-end technologies: HTML, CSS, JavaScript, and modern frameworks like React, Angular, or Vue.js.",
  "Strong back-end skills in Node.js, Python, Ruby, Java, or similar languages.",
  "Experience with relational and non-relational databases such as MySQL, PostgreSQL, MongoDB, or Firebase.",
  "Hands-on experience with version control tools (e.g., Git).",
  "Familiarity with CI/CD pipelines and containerization tools like Docker or Kubernetes.",
  "Strong understanding of web security principles and authentication protocols (OAuth, JWT).",
  "Excellent problem-solving skills and attention to detail.",
];

export const WebDeveloperJd = [
  "WordPress: Customize themes, manage plugins, and perform basic development to build and maintain WordPress websites.",
  "Shopify: Set up storefronts, customize themes, and utilize Liquid templating to create dynamic and functional e-commerce stores.",
  "Wix and Webflow: Design and manage responsive websites using these platforms, ensuring optimal performance and user experience.",
  "HTML & CSS: Develop and maintain clean, efficient, and scalable front-end code for various web projects.",
  "Cross-Platform Expertise: Work with other front-end technologies or tools relevant to web development as required.",
  "Collaborate with designers, content creators, and project managers to deliver high-quality web solutions.",
  "Optimize websites for speed, SEO, and performance across all platforms.",
  "Troubleshoot and resolve technical issues related to website functionality or integrations.",
  "Stay up-to-date with emerging trends and best practices in web development and design.",
  "2+ years of professional experience in web development.",
  "Proficiency in WordPress theme customization, plugin management, and basic PHP development.",
  "Experience with Shopify storefront setup, theme customization, and Liquid templating.",
  "Familiarity with Wix and Webflow platforms for designing and managing responsive websites.",
  "Strong foundational knowledge of HTML, CSS, and responsive design principles.",
  "Understanding of cross-browser compatibility and web accessibility standards.",
  "Knowledge of SEO and website performance optimization techniques.",
  "Strong problem-solving and debugging skills.",
];
