import React, { useState } from "react";
import "./remoteTeam.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { skillsHandler } from "../../../store/slices/hireTeam";
import SearchBar from "../../Searchbar/Searchbar";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";

const RemoteTeam = ({
  next,
  back,
  err,
  teams,
  findskills,
  delskills,
  Scope,
  selectedTeams,
  setSelectedTeams,
  showJd,
  setShowJd,
}) => {
  const [items, setItems] = useState(Scope);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  // const [selectedTeams, setSelectedTeams] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleSearch = (searchTerm) => {
    // Filter the teams based on the search term
    const filteredItems = Scope.filter((team) =>
      team.stack.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setItems(filteredItems);
  };
  // const selectTeam = (team) => {
  //   console.log("TEAMS =>", team);
  //   if (selectedTeams.includes(team)) {
  //     setSelectedTeams([...selectedTeams.filter((t) => t !== team)]);
  //   } else {
  //     setSelectedTeams([...selectedTeams, team]);
  //   }
  // };

  const selectTeam = (team) => {
    console.log("TEAMS =>", team);
    if (selectedTeams.includes(team)) {
      setSelectedTeams([]); // Deselect the team if it's already selected
      setShowJd(false);
    } else {
      setSelectedTeams([team]); // Select only the new team
      if (pathname === "/join-a-team") {
        setShowJd(true);
      }
    }
  };

  const nextStep = () => {
    if (selectedTeams.length === 0) {
      // err("Please select at least one remote team.");
      toast.error("Please select at least one remote team.")
      return;
    } else {
      if (pathname === "/join-a-team") setShowJd(false);
      next();
    }
  };

  const backStep = () => {
    back();
  };

  const selectstack = (team) => {
    findskills(team);
  };

  const delstack = (id) => {
    delskills(id);
  };

  return (
    <div className="set-content-flex-center">
      <div className="wrapper">
        <h1 className="set-remote-font text-center px-3 my-5 w-md-50 mx-auto remote-team fw-bold">
          {pathname === "/join-a-team" ? (
            <>We Value Your Skills-Tell Us How You'd Like to Contribute!</>
          ) : (
            <>Select top-notch engineers according to your need.</>
          )}
        </h1>
        <SearchBar onSearch={handleSearch} />
        <div className="pt-1 select-dev">
          {items.map((team, i) => {
            const selected = selectedTeams.includes(team.stack);
            return (
              <button
                onClick={() => {
                  selectTeam(team.stack);
                  selected ? delstack(team.id) : selectstack(team.id);
                }}
                id={`team_${team.id}`}
                value={`team_${team.stack}`}
                key={i}
                className={`select-dev-btn ${selected && "selected"} ${
                  pathname === "/join-a-team"
                    ? "d-flex justify-content-between align-items-center px-2"
                    : ""
                }`}
              >
                <span className="mt-0">{`${team.stack}`}</span>{" "}
                {pathname === "/join-a-team" ? <>&emsp;<FaEye /></> : ""}
              </button>
            );
          })}
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-2  d-none d-md-flex">
          <button className="step-btn px-5 mt-3" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-2 d-md-none">
          <button className="step-btn mx-1 mt-3" onClick={nextStep}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoteTeam;
