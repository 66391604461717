import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ThankYou = ({ next }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
      toast("Successful");
    }, 7000);
  }, []);
  const nextStep = () => {
    next();
  };

  return (
    <div className="my-5 set-align-center-thankyou">
      <div>
        <div style={{width: "80px", margin: "0 auto"}}>
          <video style={{ height: "100%", width: "100%" }} autoPlay muted>
            <source
              src={"https://res.cloudinary.com/dk04rvw9e/video/upload/v1736507687/remote%20email%20template%20assets/kb0qdymkz3zrj8oor2kg.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <h1 className=" display-2  text-center fw-bold mt-3">Thank You</h1>
        {/* <p className="text-center px-3 mx-cust">
          Thank You for reaching out to us!{" "}
        </p> */}
        <p className="text-center px-5  mx-cust">
          Thank you for taking the first step! Our team will connect with you
          soon to bring your vision to life. Let's begin this exciting journey!"
        </p>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5">
          <Link to="/">
            <button className="step-btn mx-1 mt-4" onClick={nextStep}>
              Back To Home
            </button>
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThankYou;
